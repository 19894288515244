const tranding_category_filter = [
  // {
  //   id: 0,
  //   text: "all",
  // },
  // {
  //   id: 1,
  //   svg: "art",
  //   text: "art",
  // },

  // {
  //   id: 2,
  //   svg: "collection",
  //   text: "Collectibles",
  // },
  // {
  //   id: 3,
  //   svg: "domain",
  //   text: "domain",
  // },
  // {
  //   id: 4,
  //   svg: "music",
  //   text: "music",
  // },
  // {
  //   id: 5,
  //   svg: "photography",
  //   text: "photography",
  // },
  // {
  //   id: 6,
  //   svg: "world",
  //   text: "virtual world",
  // },
];

const trendingCategoryData = [
  {
    image: "/images/riders/daxton-bennick.jpg",
    className: "Open Pro Sport",
    title: "DAXTON BENNICK",
    numberOfTitles: 6,
  },
  {
    image: "/images/riders/gavin-towers.jpg",
    className: "250 Pro Sport",
    title: "Gavin Towers",
    numberOfTitles: 1,
  },
  {
    image: "/images/riders/kyleigh-stallings.jpg",
    className: "WMX",
    title: "Kyleigh Stallings",
    numberOfTitles: 3,
  },
  {
    image: "/images/riders/krystian-janik.jpg",
    className: "250 B",
    title: "Krystian Janik",
    numberOfTitles: 2,
  },
  {
    image: "/images/riders/jeremy-fappani.jpg",
    className: "250 B Limited",
    title: "Jeremy Fappani",
    numberOfTitles: 2,
  },
  {
    image: "/images/riders/jadon-cooper.jpg",
    className: "450 B",
    title: "Jadon Cooper",
    numberOfTitles: 1,
  },
  {
    image: "/images/riders/leum-oehlhof.jpg",
    className: "450 B Limited",
    title: "Leum Oehlhof",
    numberOfTitles: 2,
  },
  {
    image: "/images/riders/casey-cochran.jpg",
    className: "Schoolboy 2",
    title: "Casey Cochran",
    numberOfTitles: 6,
  },
  {
    image: "/images/riders/jesse-wessell.jpg",
    className: "College (18-24)",
    title: "Jesse Wessell",
    numberOfTitles: 1,
  },
  {
    image: "/images/riders/heath-harrison.jpg",
    className: "Junior 25+",
    title: "Heath Harrison",
    numberOfTitles: 3,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Vet 30+",
    title: "Broc Peterson",
    numberOfTitles: 2,
  },
  {
    image: "/images/riders/jeff-emig.jpg",
    className: "Senior 40+",
    title: "Jeff Emig",
    numberOfTitles: 9,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Senior 45+",
    title: "Ronnie Renner",
    numberOfTitles: 1,
  },
  {
    image: "/images/riders/jeff-emig.jpg",
    className: "Masters (50+)",
    title: "Jeff Emig",
    numberOfTitles: 9,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Micro-E (4-7) Limited",
    title: "Jarrett Tincher",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Micro 1(4-6) Shaft Dr LTD",
    title: "Brody Jones",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Micro 2 (4-6) Limited",
    title: "Kameron Buckman",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Micro 3 (7-8) Limited",
    title: "Kannon Zabojnik",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "65cc (7-9) Limited",
    title: "Jaydin Smart",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "65cc (7-9)",
    title: "Nico Verhoeven",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "65cc (10-11) Limited",
    title: "Jackson Vick",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "65cc (10-11)",
    title: "Easton Graves",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "85cc (10-12) Limited",
    title: "Nolan Ford",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "85cc (10-12)",
    title: "Wyatt Thurman",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Mini Sr 1 (12-14)",
    title: "Colby Lessar",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Mini Sr 2 (13-15)",
    title: "Vincent Wey",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Supermini 1",
    title: "Deacon Denno",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Supermini 2",
    title: "Landon Gordon",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "125 Jr. (12-17) B/C",
    title: "Klark Robbins",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Schoolboy 1 (12-17) B/C",
    title: "Klark Robbins",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "Girls (11-16)",
    title: "Mayla Herrick",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "250 C",
    title: "Robert Weiss",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "450 C",
    title: "Chase Haynes",
    numberOfTitles: 0,
  },
  {
    image: "/images/riders/no-pic.jpg",
    className: "125 C",
    title: "Makai Olerich",
    numberOfTitles: 0,
  }
];

export { tranding_category_filter, trendingCategoryData };
