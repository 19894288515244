import { useRef } from 'react';
import HeadLine from "../headLine";
import Image from "next/image";
const NewsLetter = () => {
  const inputRef = useRef(null);
  const subscribeUser = async e => {
    e.preventDefault();
    const res = await fetch('/api/newsletter/subscribe', {
      body: JSON.stringify({
        email: inputRef.current.value
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    });
  };
  return <section className="relative py-24" data-sentry-component="NewsLetter" data-sentry-source-file="newsletter.jsx">
      <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden" data-sentry-element="picture" data-sentry-source-file="newsletter.jsx">
        <Image width={1519} height={935} src="/images/gradient.jpg" alt="gradient" className="h-full w-full object-cover" data-sentry-element="Image" data-sentry-source-file="newsletter.jsx" />
      </picture>
      <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block" data-sentry-element="picture" data-sentry-source-file="newsletter.jsx">
        <Image width={1519} height={935} src="/images/gradient_dark.jpg" alt="gradient dark" className="h-full w-full object-cover" data-sentry-element="Image" data-sentry-source-file="newsletter.jsx" />
      </picture>

      <div className="container">
        <div className="rounded-2.5xl bg-white px-12 py-20 text-center dark:bg-jacarta-700">
          <HeadLine text="Amateur Moto news delivered right to your inbox" classes="mb-5 font-display text-3xl text-jacarta-700 dark:text-white" data-sentry-element="HeadLine" data-sentry-source-file="newsletter.jsx" />

          <p className="mx-auto max-w-2xl text-center text-lg text-jacarta-700 dark:text-white">
            Join our mailing list to stay in the loop with everything amateur motocross related
          </p>

          <div className="mx-auto mt-7 max-w-md text-center">
            <form className="relative" onSubmit={e => subscribeUser(e)}>
              <input ref={inputRef} type="email" placeholder="Email address" className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white" />
              <button className="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>;
};
export default NewsLetter;