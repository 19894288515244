import React from "react";
const Championships = ({
  championships,
  classes = "dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2"
}) => {
  return <div className={classes} data-sentry-component="Championships" data-sentry-source-file="championships.jsx">
      <svg className="icon icon-world dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4" data-sentry-element="svg" data-sentry-source-file="championships.jsx">
        <use xlinkHref="/icons.svg#icon-activities" data-sentry-element="use" data-sentry-source-file="championships.jsx"></use>
      </svg>
      <span className="dark:text-jacarta-200 text-sm">{championships}</span>
    </div>;
};
export default Championships;