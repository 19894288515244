import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";
import BidsCarousel from "../carousel/bidsCarousel";
import Image from "next/image";
import Link from "next/link";
const Bids = ({
  classes = "pt-10 pb-24",
  bgWhite
}) => {
  return <section className={classes} data-sentry-component="Bids" data-sentry-source-file="bids.jsx">
      {/* <!-- Hot Bids --> */}
      {bgWhite && <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image width={1519} height={773} priority src="/images/gradient_light.jpg" alt="gradient" className="h-full w-full object-cover" />
        </picture>}
      <div className="container">
        <HeadLine text="Trending Amateurs" image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png" classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white" data-sentry-element="HeadLine" data-sentry-source-file="bids.jsx" />

        <div className="relative">
          {/* <!-- Slider --> */}
          <BidsCarousel data-sentry-element="BidsCarousel" data-sentry-source-file="bids.jsx" />
        </div>
      </div>

      <div className="mt-10 text-center">
        <Link href="/rider/claim" className="bg-accent hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all" style={{
        backgroundColor: 'red'
      }} data-sentry-element="Link" data-sentry-source-file="bids.jsx">
          Click here to claim your rider
        </Link>
      </div>
      {/* <!-- end hot bids --> */}
    </section>;
};
export default Bids;