// import '/images/item_1.jpg' from '/images/item_1.jpg';

const trendingAmatuers = [
	{
		id: 1,
		image: '/images/riders/wyatt-thurman.jpg',
		name: 'Wyatt Thurman',
		riderNumber: 247,
		bestLorettasFinsh: '1st',
		nuberOfTitles: 12,
	},
	{
		id: 2,
		image: '/images/riders/vincent-wey.jpg',
		name: 'Vincent Wey',
		riderNumber: 27,
		bestLorettasFinsh: '1st',
	},
	{
		id: 3,
		image: '/images/riders/beckham-smith.jpg',
		name: 'Beckham Smith',
		riderNumber: 4,
		bestLorettasFinsh: '1st',
	},
	{
		id: 4,
		image: '/images/riders/eidan-steinbrecher.jpg',
		name: 'Eidan Steinbrecher',
		riderNumber: 24,
		bestLorettasFinsh: '3rd',
	},
	{
		id: 5,
		image: '/images/riders/drew-adams.jpg',
		name: 'Drew Adams',
		riderNumber: 300,
		bestLorettasFinsh: '1st',
	},
];

export { trendingAmatuers };
