/* eslint-disable @next/next/no-img-element */
import React from "react";
import { HeadLine } from "../component";
import Trending_categories_items from "./trending_categories_items";
const Tranding_category = () => {
  return <section className="py-24" data-sentry-component="Tranding_category" data-sentry-source-file="tranding_categories.jsx">
      <div className="container">
        <HeadLine text="Meet the 2023 Champions" image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/26a1.png" classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white" data-sentry-element="HeadLine" data-sentry-source-file="tranding_categories.jsx" />

        {/* trending categories */}
        <Trending_categories_items data-sentry-element="Trending_categories_items" data-sentry-source-file="tranding_categories.jsx" />
      </div>
    </section>;
};
export default Tranding_category;